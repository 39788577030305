.firstColor {
  color: #20b6ad;
  background-color: rgb(32, 182, 173);
}

.secondColor {
  color: #9a76aa;
  background-color: rgb(154, 118, 170);
}

.headerContainer {
  position: relative;
  width: 100%;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  border: 4px sold rgb(247, 247, 247);
  border-radius: 15px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.6);
  z-index: 15 !important;
}
.headerSubContainerFalse {
  height: 100px;

  margin-top: -70px;
  background-color: white;
  border: 4px solid rgb(247, 247, 247);
  border-radius: 15px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.6);
  z-index: 5 !important;
}

.headerSubContainer {
  height: fit-content;
  margin-top: -20px;
  padding-top: 25px;
  background-color: white;
  border: 4px solid rgb(247, 247, 247);
  border-radius: 15px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.6);
  z-index: 5 !important;
  color: red;
  font-size: 20px;
  display: flex;
  font-weight: 800;
  align-items: flex-end;
  justify-content: center;
}

@media (max-width: 350px) {
  .headerSubContainer {
    font-size: 16px;
  }
}

.plocha {
  height: 100%;
  margin-bottom: 10px;
  margin-top: 15px;

  background-color: white;
  border: 1px solid rgb(247, 247, 247);
  border-radius: 15px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.6);
}

.menuContainer {
  margin-top: 15px;
  width: 95%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.otevirackaOut {
  text-align: left;
  font-size: 20px;
  margin: 10px;
  font-weight: 600;
  color: #5f6366;
  line-height: 1.1;
}
@media (min-width: 768px) {
  .otevirackaOut {
    display: none;
  }
}
@media (max-width: 350px) {
  .otevirackaOut {
    font-size: 16px;
  }
}

.oteviracka {
  text-align: left;
  font-size: 16px;
  margin: 10px;
  font-weight: 600;
  color: #5f6366;
  line-height: 1.1;
}
@media (max-width: 992px) {
  .oteviracka {
    font-size: 12px;
    margin: 5px;
    font-weight: 600;
    color: #5f6366;
    line-height: 1.1;
  }
}
@media (max-width: 767px) {
  .oteviracka {
    display: none;
  }
}

.otevirackaNadpis {
  margin-bottom: 5px !important;
  text-align: center;
  font-size: 18px;
}
@media (max-width: 992px) {
  .otevirackaNadpis {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .otevirackaNadpis {
    font-size: 20px;
  }
}

.time {
  text-align: center;
}
.timeZ {
  text-align: center;

  color: red;
}

.banner {
  background-color: #20b6ad;

  border: 4px outset #27cec3;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.6);
}
@media (max-width: 992px) {
  .banner {
    margin: 15px 0px;
  }
}
@media (max-width: 576px) {
  .banner {
    margin: 15px 0px;
    max-height: 120px;
  }
}

.logo {
  padding-left: 0px;
  padding-right: 5px;
}
@media (max-width: 992px) {
  .logo {
    margin: 15px 0px;
  }
}

.name {
  transform: scale(1.1);
  padding: 0px !important;
}
@media (max-width: 992px) {
  .name {
    margin: 15px 0px;
  }
}

.buttons {
  background-color: white;
  color: #5f6366;
  font-weight: 600;
  border: 4px outset rgb(252, 252, 252);
  border-radius: 15px;
  height: 50px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.6);
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
button:focus {
  outline: none;
}
@media (max-width: 992px) {
  .buttons {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .buttons {
    font-size: 25px;
    margin: 5px 0;
  }
}

.buttonActive {
  background-color: #20b6ad;
  color: white;
  border: 4px outset #27cec3;
}

.aboutHeader {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;

  color: #5f6366;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 1000;
}

h1 {
  display: inline;
}

.codeIns {
  text-align: left !important;

  color: #7f598f;
  letter-spacing: 1.5px;
  font-size: 20px;
  font-weight: 1000;
}
@media (max-width: 768px) {
  .codeIns {
    font-size: 15px;
    letter-spacing: 1px;
  }
}
.nameIns {
  text-align: left !important;

  color: #7f598f;

  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .nameIns {
    font-size: 15px;
    padding-bottom: 10px;
  }
}

.foto {
  border-radius: 15px;
  margin-top: 12px;
  transform: scale(1.1);
}
@media (max-width: 992px) {
  .foto {
    margin: 20px 0;
  }
}

.text {
  text-align: left;
  padding: 30px 45px 0px 45px;
  line-height: 1.3;
  color: #5f6366;
  font-size: 20px;
}
@media (max-width: 500px) {
  .text {
    font-size: 16px;
    padding: 30px 15px 0px 15px;
  }
}

.imageMenu {
  width: 50px;
  cursor: pointer;
  margin: 4px;
  border-radius: 4px;
}

.imageActive {
  box-shadow: 0 0 0 3px #9a76aa;
}

.galleryMenu {
  margin-top: 40px;
  margin-bottom: 40px;
}

.arrows {
  width: 75px;
  height: 50px;
  color: #9a76aa;
  background-color: white;
  font-size: 25px;
  font-weight: 1000;
  border: 5px outset #9a76aa;
  border-radius: 20px;
  margin: 10px 0;

  cursor: pointer;
}
.arrows:hover {
  color: white;
  background-color: #9a76aa;
}
@media (max-width: 768px) {
  .arrowDole {
    display: none;
  }
}

@media (min-width: 768px) {
  .arrowHore {
    display: none;
  }
}

.mainFoto {
  max-width: 100%;
  border-radius: 10px;
}

.footer {
  padding: 10px 0;
  line-height: 1.3;
  color: #555657;
  font-size: 15px;
}
@media (max-width: 576px) {
  .fotter {
    font-size: 12px;
  }
}
.video {
  margin: 45px;
}
@media (max-width: 576px) {
  .video {
    margin: 0px;
    margin-top: 35px;
  }
}

.date {
  color: #5f6366;
  text-align: left;
}
.link {
  text-align: left;
  color: #5f6366;
  text-decoration: none;
}
a:hover {
  text-align: left;
  color: #9a76aa;
  text-decoration: none;
}
a {
  color: #20b6ad;
}

.contactHead {
  color: #7f598f;
  letter-spacing: 1.5px;
  font-size: 20px;
  font-weight: 1000;
}

.contactBody {
  color: #5f6366;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}
.contact {
  padding-top: 25px;
  width: 100%;
  text-align: center;
}
@media (min-width: 576px) {
  .shown {
    display: none;
  }
}
@media (max-width: 576px) {
  .shownBig {
    display: none;
  }
}
