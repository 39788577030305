.App {
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  color: #5f6366;
}

.App-header {
  background-image: linear-gradient(to right, rgb(32, 182, 173), white);
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: black;
}
